exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-greenquadrant-tsx": () => import("./../../../src/pages/greenquadrant.tsx" /* webpackChunkName: "component---src-pages-greenquadrant-tsx" */),
  "component---src-pages-island-tsx": () => import("./../../../src/pages/island.tsx" /* webpackChunkName: "component---src-pages-island-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-articles-tsx": () => import("./../../../src/templates/articles.tsx" /* webpackChunkName: "component---src-templates-articles-tsx" */),
  "component---src-templates-biodiversity-tsx": () => import("./../../../src/templates/biodiversity.tsx" /* webpackChunkName: "component---src-templates-biodiversity-tsx" */),
  "component---src-templates-catalogue-tsx": () => import("./../../../src/templates/catalogue.tsx" /* webpackChunkName: "component---src-templates-catalogue-tsx" */),
  "component---src-templates-compliance-tsx": () => import("./../../../src/templates/compliance.tsx" /* webpackChunkName: "component---src-templates-compliance-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-content-hub-tsx": () => import("./../../../src/templates/content-hub.tsx" /* webpackChunkName: "component---src-templates-content-hub-tsx" */),
  "component---src-templates-corporates-tsx": () => import("./../../../src/templates/corporates.tsx" /* webpackChunkName: "component---src-templates-corporates-tsx" */),
  "component---src-templates-csrd-tsx": () => import("./../../../src/templates/csrd.tsx" /* webpackChunkName: "component---src-templates-csrd-tsx" */),
  "component---src-templates-customers-tsx": () => import("./../../../src/templates/customers.tsx" /* webpackChunkName: "component---src-templates-customers-tsx" */),
  "component---src-templates-emission-factors-tsx": () => import("./../../../src/templates/emission-factors.tsx" /* webpackChunkName: "component---src-templates-emission-factors-tsx" */),
  "component---src-templates-esg-tsx": () => import("./../../../src/templates/esg.tsx" /* webpackChunkName: "component---src-templates-esg-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-events-tsx": () => import("./../../../src/templates/events.tsx" /* webpackChunkName: "component---src-templates-events-tsx" */),
  "component---src-templates-financedemissions-tsx": () => import("./../../../src/templates/financedemissions.tsx" /* webpackChunkName: "component---src-templates-financedemissions-tsx" */),
  "component---src-templates-get-in-touch-tsx": () => import("./../../../src/templates/get-in-touch.tsx" /* webpackChunkName: "component---src-templates-get-in-touch-tsx" */),
  "component---src-templates-glossary-tsx": () => import("./../../../src/templates/glossary.tsx" /* webpackChunkName: "component---src-templates-glossary-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-industry-tsx": () => import("./../../../src/templates/industry.tsx" /* webpackChunkName: "component---src-templates-industry-tsx" */),
  "component---src-templates-jobs-tsx": () => import("./../../../src/templates/jobs.tsx" /* webpackChunkName: "component---src-templates-jobs-tsx" */),
  "component---src-templates-landing-partner-tsx": () => import("./../../../src/templates/landing-partner.tsx" /* webpackChunkName: "component---src-templates-landing-partner-tsx" */),
  "component---src-templates-landing-tsx": () => import("./../../../src/templates/landing.tsx" /* webpackChunkName: "component---src-templates-landing-tsx" */),
  "component---src-templates-legal-tsx": () => import("./../../../src/templates/legal.tsx" /* webpackChunkName: "component---src-templates-legal-tsx" */),
  "component---src-templates-main-landing-tsx": () => import("./../../../src/templates/main-landing.tsx" /* webpackChunkName: "component---src-templates-main-landing-tsx" */),
  "component---src-templates-partners-tsx": () => import("./../../../src/templates/partners.tsx" /* webpackChunkName: "component---src-templates-partners-tsx" */),
  "component---src-templates-platform-tsx": () => import("./../../../src/templates/platform.tsx" /* webpackChunkName: "component---src-templates-platform-tsx" */),
  "component---src-templates-profile-tsx": () => import("./../../../src/templates/profile.tsx" /* webpackChunkName: "component---src-templates-profile-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */),
  "component---src-templates-sfdr-tsx": () => import("./../../../src/templates/sfdr.tsx" /* webpackChunkName: "component---src-templates-sfdr-tsx" */),
  "component---src-templates-story-tsx": () => import("./../../../src/templates/story.tsx" /* webpackChunkName: "component---src-templates-story-tsx" */),
  "component---src-templates-supplychain-tsx": () => import("./../../../src/templates/supplychain.tsx" /* webpackChunkName: "component---src-templates-supplychain-tsx" */)
}

