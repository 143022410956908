import React, { createContext } from 'react';
import { I18nextProvider } from 'react-i18next';
import browserLang from 'browser-lang';
import { withPrefix, WrapPageElementBrowserArgs } from 'gatsby';
import i18next, { i18n as I18n } from 'i18next';

export const LANGUAGE_KEY = 'lang';
const DEFAULT_LANG = 'en';
const DEFAULT_SUPPORTED_LNGS = ['en', 'fr', 'de'];

const getLangInUrl = (pathname: string) => pathname.match(/^\/([\w]{2})(\/|)/g)?.[0]?.replaceAll('/', '');

export const I18Context = createContext<{ supportedLocales: string[] }>({
    supportedLocales: ['en']
});

const withI18next = (i18n: I18n, supportedLocales: string[]) => (children: any) => {
    return (
        <I18nextProvider i18n={i18n}>
            <I18Context.Provider value={{ supportedLocales }}>{children}</I18Context.Provider>
        </I18nextProvider>
    );
};

const removePathPrefix = (pathname: string, stripTrailingSlash: boolean) => {
    const pathPrefix = withPrefix('/');
    let result = pathname;

    if (pathname.startsWith(pathPrefix)) {
        result = pathname.replace(pathPrefix, '/');
    }

    if (stripTrailingSlash && result.endsWith('/')) {
        return result.slice(0, -1);
    }

    return result;
};

const createLocaleUrl = (location: { pathname: string; hash: string; queryParams: string }, detectedLocale: string) => {
    const urlLang = getLangInUrl(location.pathname);

    if (detectedLocale === DEFAULT_LANG) {
        return urlLang === DEFAULT_LANG
            ? location.pathname
            : `${removePathPrefix(location.pathname.replace(`/${urlLang}`, ''), false)}${location.queryParams}${
                  location.hash
              }`;
    } else {
        return urlLang === DEFAULT_LANG
            ? `/${detectedLocale}${removePathPrefix(location.pathname, false)}${location.queryParams}${location.hash}`
            : `${removePathPrefix(location.pathname.replace(urlLang, detectedLocale), false)}${location.queryParams}${
                  location.hash
              }`;
    }
};

export const wrapI18nPageElement = ({ element, props }: WrapPageElementBrowserArgs<any, any>) => {
    if (!props) {
        return;
    }

    const { pageContext, location } = props;

    const { search } = location;

    if (!pageContext.i18n) {
        return element;
    }

    // Skip build, Browsers only
    if (typeof window !== 'undefined' && pageContext.autoDetect && !window.localStorage.getItem(LANGUAGE_KEY)) {
        let detected = browserLang({
            languages: pageContext.i18n?.languages,
            fallback: DEFAULT_LANG
        });

        if (pageContext.i18n && !pageContext.i18n.languages?.includes(detected)) {
            detected = pageContext.language;
        }

        window.localStorage.setItem(LANGUAGE_KEY, 'true');
        // This is used to prevent looping in dev mode. As the app is not ssr on dev it can create a loop
        const urlLang = getLangInUrl(location.pathname) ?? DEFAULT_LANG;

        if (detected !== pageContext.i18n.language && urlLang === pageContext.i18n.language) {
            const queryParams = search || '';
            const newUrl = withPrefix(createLocaleUrl({ ...location, queryParams }, detected));

            window['___replace'](newUrl);

            return null;
        }
    }

    const i18n = i18next.createInstance();

    i18n.init({
        lng: pageContext.language || DEFAULT_LANG,
        fallbackLng: pageContext.defaultLanguage || DEFAULT_LANG,
        defaultNS: 'translation',
        supportedLngs: pageContext.i18n?.supportedLngs || DEFAULT_SUPPORTED_LNGS,
        react: {
            useSuspense: false
        },
        resources: {
            en: {
                translation: require('../../locales/en/translation.json')
            },
            ...(pageContext.language !== DEFAULT_LANG
                ? {
                      [pageContext.language]: {
                          translation: pageContext.i18n?.resources
                      }
                  }
                : {})
        }
    });

    if (i18n.language !== pageContext.language) {
        i18n.changeLanguage(pageContext.language);
    }

    return withI18next(i18n, pageContext?.i18n?.languages ?? ['en'])(element);
};
