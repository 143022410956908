import React from 'react';
import { Box, BoxProps } from '@mui/material';

interface SwEmojiProps extends BoxProps<'span'> {
    emoji: string;
    label?: string;
}

const SwEmoji: React.FC<SwEmojiProps> = ({ emoji, label }) => (
    <Box aria-hidden={label ? 'false' : 'true'} aria-label={label ? label : ''} component={'span'} role={'img'}>
        {emoji}
    </Box>
);

export default SwEmoji;
