export function isEmpty(text: string) {
    return text.trim() === '';
}

export function isNotEmpty(text: string) {
    return !isEmpty(text);
}

export function getANumberBetween0and9FromAString(string) {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
        hash += string.charCodeAt(i);
    }

    // This gives a number
    const hashString = hash.toString();

    // just return the last digit of it. It is lame, but should give some form of randomness but always return
    // a number between 0 and 9 (what we need) and always the same number for a given string (also what we need)
    return parseInt(hashString.charAt(hashString.length - 1));
}

export function hashString(string: string) {
    return string.split('').reduce((a, b) => {
        // eslint-disable-next-line no-bitwise
        a = (a << 5) - a + b.charCodeAt(0);

        // eslint-disable-next-line no-bitwise
        return a & a;
    }, 0);
}

export function capitalizeFirstCharacter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function slug(text: string): string {
    if (typeof text !== 'string') {
        return '';
    }

    return text
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9 -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
}

export function pluralize(word: string, count: number, options?: { letter?: string; plural?: string }) {
    if (options?.plural) {
        return count > 1 ? options?.plural : word;
    } else {
        return `${word}${count > 1 ? options?.letter ?? 's' : ''}`;
    }
}

export function getDomaineName(url: string) {
    // eslint-disable-next-line no-control-regex
    const regex = RegExp('^(?:https?://)?(?:[^@\n]+@)?(?:www.)?([^:/\n?]+)', 'g');

    return regex.exec(url)?.at(1);
}

export function toPascalCase(str) {
    return str
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((x, index) => {
            if (!index) {
                return x.toLowerCase();
            }

            return x.charAt(0).toUpperCase() + x.slice(1).toLowerCase();
        })
        .join('');
}
