import React from 'react';
import { useTheme, styled } from '@mui/material';

const StyledDiv = styled('div')<SwLineProps>`
    height: 0px;
    border-bottom: ${(props) => (props.color ? props.color : useTheme().palette.primary.main)}
        ${(props) => (props.thickness ? props.thickness : 1)}px solid;
    margin-top: ${({ spacing, spacingTop }) => {
        const pixels =
            spacingTop !== undefined
                ? useTheme().spacing(spacingTop)
                : spacing !== undefined
                  ? useTheme().spacing(spacing)
                  : useTheme().spacing(2);
        return pixels;
    }};
    margin-bottom: ${({ spacing, spacingBottom }) => {
        const pixels =
            spacingBottom !== undefined
                ? useTheme().spacing(spacingBottom)
                : spacing !== undefined
                  ? useTheme().spacing(spacing)
                  : useTheme().spacing(2);
        return pixels;
    }};
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    opacity: ${(props) => (props.opacity ? props.opacity : 1)};
    width: 100%;
`;

const SwLine = (props: SwLineProps) => <StyledDiv {...props}></StyledDiv>;

interface SwLineProps {
    color?: string;
    thickness?: number;
    ref?: any;
    opacity?: number;
    spacing?: number;
    spacingTop?: number;
    spacingBottom?: number;
}

export default SwLine;
