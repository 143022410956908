import React, { StrictMode, Suspense } from 'react';
import BaseLayout from '../../../BaseLayout';
import { GenericModalProvider } from '../../../lib/hooks/useGenericModal';
import ErrorBoundary from './ErrorBoundary';

export interface IProviderProps {
    forSSR: boolean;
}

export const ApplicationProvider = (props) => (
    <StrictMode>
        {props.forSSR ? (
            <ErrorBoundary>
                <BaseLayout>
                    <GenericModalProvider>{props.children}</GenericModalProvider>
                </BaseLayout>
            </ErrorBoundary>
        ) : (
            <Suspense fallback={'Loading...'}>
                <ErrorBoundary>
                    <BaseLayout>
                        <GenericModalProvider>{props.children}</GenericModalProvider>
                    </BaseLayout>
                </ErrorBoundary>
            </Suspense>
        )}
    </StrictMode>
);
