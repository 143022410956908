import React from 'react';
import {
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Box,
    useTheme,
    Typography,
    TypographyVariant as Variant,
    styled
} from '@mui/material';
import CheckMarkWhite from '../../../assets/icons/check-mark-white.svg';
import { skyBlueStrong, grey5, black, grey3 } from '../../../lib/components/bedrock/SwColors';

const SwIcon = styled('div')`
    width: ${({ theme }) => theme.spacing(3)};
    height: ${({ theme }) => theme.spacing(3)};
    left: 0px;
    top: 0px;
    background: ${grey5};
    border-radius: ${({ theme }) => theme.spacing(1)};
`;

type SwCheckboxKind = 'primary' | 'secondary' | 'tertiary';

const SwCheckedIcon = styled(Box)<{ $kind?: SwCheckboxKind; $reverse?: boolean }>`
    width: ${({ theme }) => theme.spacing(3)};
    height: ${({ theme }) => theme.spacing(3)};
    left: 0px;
    top: 0px;
    background: ${({ $kind }) => ($kind === 'tertiary' ? grey3 : $kind === 'secondary' ? black : skyBlueStrong)};
    border-radius: ${({ theme }) => theme.spacing(1)};
    img {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    padding-right: ${({ $reverse }) => ($reverse ? '0px !important' : '')};
`;

const StyledFormControlLabel = styled(FormControlLabel)<{ $reverse?: boolean; $row?: boolean }>`
    margin-right: ${({ $reverse, $row }) =>
        $reverse ? '-' + useTheme().spacing(1) : $row ? useTheme().spacing(2) : '0px !important'};
    flex-direction: ${({ $reverse }) => ($reverse ? 'row-reverse' : 'row')};
`;

export interface SwCheckboxProps {
    values: string[];
    answer: string[];
    setAnswer: (answer: string[]) => void;
    renderValue?: (value: string) => React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    variant?: Variant | 'inherit';
    hightlightActiveItems?: boolean;
    kind?: SwCheckboxKind;
    reverse?: boolean;
    row?: boolean;
    dataTestId?: string;
}

const SwCheckbox = (props: SwCheckboxProps) => {
    const updateCheckbox = (event: React.ChangeEvent<HTMLInputElement>, state: boolean) => {
        const name = event.target.name;
        if (state && props.answer.indexOf(name) === -1) {
            const newAnswer = [...props.answer, name];
            props.setAnswer(newAnswer);
        } else if (!state && props.answer.indexOf(name) !== -1) {
            const newAnswer = [...props.answer];
            newAnswer.splice(newAnswer.indexOf(name), 1);
            props.setAnswer(newAnswer);
        }
    };
    return (
        <FormControl component={'fieldset'}>
            <FormGroup row={props.row}>
                {props.values.map((value) => {
                    const checked = props.answer.indexOf(value) !== -1;
                    return (
                        <StyledFormControlLabel
                            $reverse={props.reverse}
                            $row={props.row}
                            control={
                                <Checkbox
                                    checked={checked}
                                    checkedIcon={
                                        <SwCheckedIcon $kind={props.kind} $reverse={props.reverse}>
                                            <img alt={'check mark'} src={CheckMarkWhite} />
                                        </SwCheckedIcon>
                                    }
                                    icon={<SwIcon />}
                                    name={value}
                                    onChange={updateCheckbox}
                                    onClick={props.onClick}
                                />
                            }
                            data-test-id={props.dataTestId}
                            disabled={props.disabled}
                            key={value}
                            label={
                                props.renderValue ? (
                                    props.renderValue(value)
                                ) : (
                                    <Typography
                                        color={props.hightlightActiveItems && !checked ? 'textSecondary' : 'inherit'}
                                        variant={props.variant}
                                    >
                                        {value}
                                    </Typography>
                                )
                            }
                        />
                    );
                })}
            </FormGroup>
        </FormControl>
    );
};

export default SwCheckbox;
