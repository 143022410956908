/* eslint-disable no-empty-function */
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { Grid, Modal, Typography, styled } from '@mui/material';
import SwEmoji from '../../components/v1/SwEmoji';
import SwLine from '../../components/v1/SwLine';
import SwLink from '../../components/v1/SwLink';
import SwVerticalSpacer from '../../components/v1/SwVerticalSpacer';
import SwCheckbox from '../../components/v1/forms/SwCheckbox';
import { grey5, white } from '../components/bedrock/SwColors';
import SwButton, { SwButtonKind } from '../components/bedrock/button/SwButton';

export type ModalType = 'normal' | 'danger' | 'primary' | 'success';

export interface InputModalProps {
    acceptLabel?: string;
    acceptLabelDisplayed?: boolean;
    body?: ReactNode;
    cancelLabel?: string;
    cancelLabelDisplayed?: boolean;
    emoji?: string;
    error?: boolean;
    onAccept?: () => void;
    onCancel?: () => void;
    title?: string;
    type?: ModalType;
    width?: number | string;
    displayCheckbox?: boolean;
    checkboxKey?: string;
    checkboxText?: string;
}

export interface ModalProps {
    acceptLabel: string;
    acceptLabelDisplayed: boolean;
    body: ReactNode;
    cancelLabel: string;
    cancelLabelDisplayed: boolean;
    emoji?: string;
    error: boolean;
    onAccept: () => void;
    onCancel: () => void;
    title: string;
    type: ModalType;
    width: number | string;
    displayCheckbox?: boolean;
    checkboxKey?: string;
    checkboxText?: string;
}

const defaultErrorBody = (
    <Typography variant={'body2'}>
        We couldn’t take this action. If you keep having problems,{' '}
        <SwLink href={'mailto:support@sweep.net'} noGatsbyLink={true} underlined={true} variant={'body2'}>
            get in touch
        </SwLink>
        .
    </Typography>
);
const defaultModalProps = (error?: boolean): ModalProps => ({
    acceptLabel: 'Ok',
    acceptLabelDisplayed: true,
    body: error ? defaultErrorBody : '',
    cancelLabel: 'Cancel',
    cancelLabelDisplayed: !error,
    emoji: undefined,
    error: error || false,
    onAccept: error ? () => {} : () => {},
    onCancel: error ? () => {} : () => {},
    title: error ? 'Oops, something went wrong' : 'Do you want really want to make this action ?',
    type: 'normal',
    width: '368px',
    displayCheckbox: false,
    checkboxKey: 'genericKey',
    checkboxText: "Don't show this again"
});

export interface GenericModalHookResult {
    openErrorModal: (modalProps?: InputModalProps) => void;
    openConfirmModal: (modalProps?: InputModalProps) => void;
}

const GenericModalContext = React.createContext<GenericModalHookResult>({
    openErrorModal: () => {},
    openConfirmModal: () => {}
} as GenericModalHookResult);

export const useGenericModal = (): GenericModalHookResult => useContext(GenericModalContext);

const StyledModalBody = styled('div')<{ width: string | number }>`
    background-color: ${white};
    border: 1px solid ${grey5};
    border-radius: ${({ theme }) => theme.spacing(1)};
    left: 50%;
    outline: none;
    padding: ${({ theme }) => theme.spacing(2)};
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: ${(props) => props.width};
`;

export const GenericModalProvider: React.FunctionComponent<GenericModalProviderProps> = (
    props: GenericModalProviderProps
) => {
    const [open, setOpen] = React.useState(false);
    const [modalProps, setModalProps] = useState<ModalProps>(defaultModalProps(false));
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [returnValue, setReturnValue] = useState({ openErrorModal: () => {}, openConfirmModal: () => {} });

    useEffect(() => {
        const openErrorModal = (modalProps?: InputModalProps) => {
            const props = {
                acceptLabel: modalProps?.acceptLabel ?? defaultModalProps(modalProps?.error || true).acceptLabel,
                acceptLabelDisplayed:
                    modalProps?.acceptLabelDisplayed ??
                    defaultModalProps(modalProps?.error || true).acceptLabelDisplayed,
                body: modalProps?.body ?? defaultModalProps(modalProps?.error || true).body,
                cancelLabel: modalProps?.cancelLabel ?? defaultModalProps(modalProps?.error || true).cancelLabel,
                cancelLabelDisplayed:
                    modalProps?.cancelLabelDisplayed ??
                    defaultModalProps(modalProps?.error || true).cancelLabelDisplayed,
                emoji: modalProps?.emoji ?? defaultModalProps(modalProps?.error || true).emoji,
                error: modalProps?.error ?? defaultModalProps(modalProps?.error || true).error,
                onAccept: modalProps?.onAccept ?? defaultModalProps(modalProps?.error || true).onAccept,
                onCancel: modalProps?.onCancel ?? defaultModalProps(modalProps?.error || true).onCancel,
                title: modalProps?.title ?? defaultModalProps(modalProps?.error || true).title,
                type: modalProps?.type ?? defaultModalProps(modalProps?.error || true).type,
                width: modalProps?.width ?? defaultModalProps(modalProps?.error || true).width,
                displayCheckbox:
                    modalProps?.displayCheckbox ?? defaultModalProps(modalProps?.error || true).displayCheckbox,
                checkboxKey: modalProps?.checkboxKey ?? defaultModalProps(modalProps?.error || true).checkboxKey,
                checkboxText: modalProps?.checkboxText ?? defaultModalProps(modalProps?.error || true).checkboxText
            };
            setModalProps(props);
            setOpen(true);
        };

        const openConfirmModal = (modalProps?: InputModalProps) => {
            const props = {
                acceptLabel: modalProps?.acceptLabel ?? defaultModalProps(modalProps?.error || false).acceptLabel,
                acceptLabelDisplayed:
                    modalProps?.acceptLabelDisplayed ??
                    defaultModalProps(modalProps?.error || false).acceptLabelDisplayed,
                body: modalProps?.body ?? defaultModalProps(modalProps?.error || false).body,
                cancelLabel: modalProps?.cancelLabel ?? defaultModalProps(modalProps?.error || false).cancelLabel,
                cancelLabelDisplayed:
                    modalProps?.cancelLabelDisplayed ??
                    defaultModalProps(modalProps?.error || false).cancelLabelDisplayed,
                emoji: modalProps?.emoji ?? defaultModalProps(modalProps?.error || false).emoji,
                error: modalProps?.error ?? defaultModalProps(modalProps?.error || false).error,
                onAccept: modalProps?.onAccept ?? defaultModalProps(modalProps?.error || false).onAccept,
                onCancel: modalProps?.onCancel ?? defaultModalProps(modalProps?.error || false).onCancel,
                title: modalProps?.title ?? defaultModalProps(modalProps?.error || false).title,
                type: modalProps?.type ?? defaultModalProps(modalProps?.error || false).type,
                width: modalProps?.width ?? defaultModalProps(modalProps?.error || false).width,
                displayCheckbox:
                    modalProps?.displayCheckbox ?? defaultModalProps(modalProps?.error || false).displayCheckbox,
                checkboxKey: modalProps?.checkboxKey ?? defaultModalProps(modalProps?.error || false).checkboxKey,
                checkboxText: modalProps?.checkboxText ?? defaultModalProps(modalProps?.error || false).checkboxText
            };
            setModalProps(props);
            setOpen(true);
        };
        setReturnValue({ openErrorModal, openConfirmModal });
    }, [setOpen, setModalProps]);

    let buttonKind: SwButtonKind = null;
    switch (modalProps.type) {
        case 'normal': {
            buttonKind = SwButtonKind.Default;
            break;
        }
        case 'danger': {
            buttonKind = SwButtonKind.Danger;
            break;
        }
        case 'primary': {
            buttonKind = SwButtonKind.Primary;
            break;
        }
        case 'success': {
            buttonKind = SwButtonKind.Success;
            break;
        }
    }

    return (
        <GenericModalContext.Provider value={returnValue}>
            {props.children}
            <Modal data-test-id={modalProps.error ? 'sweep-modal-error' : 'sweep-modal-confirm'} open={open}>
                <StyledModalBody width={modalProps.width}>
                    <Grid alignItems={'center'} container={true} spacing={1}>
                        <Grid item={true}>
                            <Typography variant={'h4'}>{modalProps.title}</Typography>
                        </Grid>
                        {modalProps.emoji && (
                            <Grid item={true}>
                                <SwEmoji emoji={modalProps.emoji} />
                            </Grid>
                        )}
                    </Grid>
                    <SwVerticalSpacer spacing={2} />
                    {modalProps.body}
                    <SwVerticalSpacer spacing={1} />
                    {modalProps.displayCheckbox && (
                        <SwCheckbox
                            answer={
                                isChecked
                                    ? modalProps.checkboxText !== undefined
                                        ? [modalProps.checkboxText]
                                        : ["Don't show this again"]
                                    : []
                            }
                            kind={'secondary'}
                            setAnswer={() => {
                                setIsChecked(!isChecked);
                            }}
                            values={
                                modalProps.checkboxText !== undefined
                                    ? [modalProps.checkboxText]
                                    : ["Don't show this again"]
                            }
                            variant={'body2'}
                        />
                    )}
                    <SwLine color={grey5} spacingBottom={2} spacingTop={1} />
                    <Grid container={true} spacing={1}>
                        {modalProps.acceptLabelDisplayed && (
                            <Grid item={true}>
                                <SwButton
                                    ariaLabel={'Ok'}
                                    dataTestId={'sweep-modal-accept-button'}
                                    kind={buttonKind}
                                    onClick={() => {
                                        setOpen(false);
                                        modalProps.onAccept();
                                    }}
                                >
                                    {modalProps.acceptLabel}
                                </SwButton>
                            </Grid>
                        )}
                        {modalProps.cancelLabelDisplayed && (
                            <Grid item={true}>
                                <SwButton
                                    ariaLabel={'Cancel'}
                                    dataTestId={'sweep-modal-cancel-button'}
                                    kind={SwButtonKind.Secondary}
                                    onClick={() => {
                                        setOpen(false);
                                        modalProps.onCancel();
                                    }}
                                >
                                    {modalProps.cancelLabel}
                                </SwButton>
                            </Grid>
                        )}
                    </Grid>
                </StyledModalBody>
            </Modal>
        </GenericModalContext.Provider>
    );
};

interface GenericModalProviderProps {
    children?: any;
}
