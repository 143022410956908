import React from 'react';
import { Typography, TypographyProps, styled, css } from '@mui/material';
import { boldFontFamily, editorialFontFamily } from '../../../../BaseLayout';
import { isDefined } from '../../../helpers/object';

const materialColorKeys = ['initial', 'inherit', 'primary', 'secondary', 'textPrimary', 'textSecondary', 'error'];

export type SwTypographyProps = Omit<TypographyProps, 'color'> & {
    color?: TypographyProps['color'] | string;
    bold?: boolean;
    uppercase?: boolean;
    editorial?: boolean;
    cursor?: string;
    keyTestId?: string;
    italic?: boolean;
    ref?: any;
};

type StyledTypographyProps = {
    $color?: string;
    $bold?: boolean;
    $italic?: boolean;
    $cursor?: string;
    $uppercase?: boolean;
    $editorial?: boolean;
};

export const StyledTypography = styled(Typography)<StyledTypographyProps>`
    & {
        ${({ $bold }) =>
            $bold
                ? css`
                      font-weight: bold;
                      font-family: ${boldFontFamily};
                  `
                : ''}

        ${({ $italic }) =>
            $italic
                ? css`
                      font-style: italic;
                  `
                : ''}

        ${({ $editorial }) =>
            $editorial
                ? css`
                      font-weight: bold;
                      font-family: ${editorialFontFamily};
                  `
                : ''}

    ${({ $uppercase }) =>
            $uppercase
                ? css`
                      text-transform: uppercase;
                  `
                : ''}

    ${({ $color }) =>
            $color !== undefined
                ? css`
                      color: ${$color};
                  `
                : ''}
    ${({ $cursor }) =>
            $cursor
                ? css`
                      cursor: ${$cursor};
                  `
                : ''}
    }
`;

const SwTypography: React.FunctionComponent<SwTypographyProps> = React.forwardRef<HTMLSpanElement, SwTypographyProps>(
    (props, ref) => {
        const { variant, color, bold, uppercase, editorial, children, keyTestId, cursor, italic, ...others } = props;

        const colorProps =
            typeof color === 'string'
                ? materialColorKeys.includes(color)
                    ? { color: color as TypographyProps['color'] }
                    : { $color: color }
                : { color: color as TypographyProps['color'] };

        return (
            <StyledTypography
                {...others}
                data-test-id={isDefined(keyTestId) ? `sweep-${keyTestId}` : undefined}
                ref={ref}
                variant={variant ?? 'body2'}
                {...colorProps}
                $bold={bold}
                $cursor={cursor}
                $editorial={editorial}
                $italic={italic}
                $uppercase={uppercase}
            >
                {children}
            </StyledTypography>
        );
    }
);

export default SwTypography;
