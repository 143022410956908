import React from 'react';
import { createTheme, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import StylesProvider from '@mui/styles/StylesProvider';
import { colors } from '@lib/components/bedrock/fundations';
import { black, grey5, white } from './lib/components/bedrock/SwColors';
import { zIndexes } from './lib/enums/zIndexes';
import './styles/layout.css';

const muiPalette = {
    primary: {
        main: colors.sky[400],
        light: colors.sky[100],
        dark: colors.sky[500],
        contrastText: colors.sky[100],
        200: colors.sky[200],
        300: colors.sky[300]
    },
    secondary: {
        main: colors.grey[400],
        light: colors.grey[100],
        dark: colors.grey[500],
        contrastText: colors.grey[100],
        200: colors.grey[200],
        300: colors.grey[300]
    },
    error: {
        main: colors.fire[400],
        light: colors.fire[100],
        dark: colors.fire[500],
        contrastText: colors.fire[100],
        200: colors.sky[200],
        300: colors.sky[300]
    },
    warning: {
        main: colors.crop[400],
        light: colors.crop[100],
        dark: colors.crop[500],
        contrastText: colors.crop[100],
        200: colors.crop[200],
        300: colors.crop[300]
    },
    success: {
        main: colors.grass[400],
        light: colors.grass[100],
        dark: colors.grass[500],
        contrastText: colors.grass[100],
        200: colors.grass[200],
        300: colors.grass[300]
    },
    text: {
        primary: colors.text.default,
        secondary: colors.text.secondary,
        disabled: colors.text.disabled,
        onAction: colors.common.white
    },
    divider: colors.border,
    surface: {
        subdued: colors.surface.subdued,
        background: colors.surface.background
    },
    background: {
        default: colors.surface.default,
        paper: colors.common.white
    },
    grey: {
        100: colors.grey[100],
        200: colors.grey[200],
        300: colors.grey[300],
        400: colors.grey[400],
        500: colors.grey[500],
        600: colors.grey[600],
        700: colors.grey[700]
    },
    decorative: {
        sea: {
            main: colors.sea[400],
            light: colors.sea[100],
            dark: colors.sea[500],
            contrastText: colors.sea[100],
            200: colors.sea[200],
            300: colors.sea[300]
        },
        flower: {
            main: colors.flower[400],
            light: colors.flower[100],
            dark: colors.flower[500],
            contrastText: colors.flower[100],
            200: colors.flower[200],
            300: colors.flower[300]
        },
        coral: {
            main: colors.coral[400],
            light: colors.coral[100],
            dark: colors.coral[500],
            contrastText: colors.coral[100],
            200: colors.coral[200],
            300: colors.coral[300]
        },
        glacier: {
            main: colors.glacier[400],
            light: colors.glacier[100],
            dark: colors.glacier[500],
            contrastText: colors.glacier[100],
            200: colors.glacier[200],
            300: colors.glacier[300]
        },
        algea: {
            main: colors.algea[400],
            light: colors.algea[100],
            dark: colors.algea[500],
            contrastText: colors.algea[100],
            200: colors.algea[200],
            300: colors.algea[300]
        }
    }
};

type MuiPalette = typeof muiPalette;
declare module '@mui/material/styles' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface Palette extends MuiPalette {}
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface PaletteOptions extends MuiPalette {}
}

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles' {
    interface TypographyVariants {
        bodyStrong: React.CSSProperties;
        headingStrong: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        bodyStrong?: React.CSSProperties;
        headingStrong?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        bodyStrong: true;
        headingStrong?: true;
    }
}

export const sweepTheme = createTheme({
    palette: muiPalette,
    typography: {
        fontFamily: 'MaisonNeue-Book',
        h1: {
            fontWeight: 'bold',
            fontFamily: 'MaisonNeue-Bold',
            fontSize: '96px',
            lineHeight: '104px',
            '@media (max-width:600px)': {
                fontSize: '48px',
                lineHeight: '56px'
            },
            '&.ellipsis': {
                display: '-webkit-box',
                maxWidth: '100%',
                margin: '0 auto',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        },
        h2: {
            fontWeight: 'bold',
            fontFamily: 'MaisonNeue-Bold',
            fontSize: '64px',
            lineHeight: '72px',
            '@media (max-width:600px)': {
                fontSize: '40px',
                lineHeight: '44px'
            },
            '&.ellipsis': {
                display: '-webkit-box',
                maxWidth: '100%',
                margin: '0 auto',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        },
        h3: {
            fontWeight: 'bold',
            fontFamily: 'MaisonNeue-Bold',
            fontSize: '32px',
            lineHeight: '40px',
            '&.ellipsis': {
                display: '-webkit-box',
                maxWidth: '100%',
                margin: '0 auto',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        },
        h4: {
            fontWeight: 'bold',
            fontFamily: 'MaisonNeue-Bold',
            fontSize: '24px',
            lineHeight: '32px',
            '@media (max-width:600px)': {
                fontSize: '22px',
                lineHeight: '28px'
            },
            '&.ellipsis': {
                display: '-webkit-box',
                maxWidth: '100%',
                margin: '0 auto',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        },
        subtitle1: {
            fontWeight: 'normal',
            fontFamily: 'MaisonNeue-book',
            fontSize: '24px',
            lineHeight: '32px',
            '@media (max-width:600px)': {
                fontSize: '22px',
                lineHeight: '28px'
            },
            '&.ellipsis': {
                display: '-webkit-box',
                maxWidth: '100%',
                margin: '0 auto',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        },
        h5: {
            fontWeight: 'bold',
            fontFamily: 'MaisonNeue-Bold',
            fontSize: '16px',
            lineHeight: '24px',
            '&.ellipsis': {
                display: '-webkit-box',
                maxWidth: '100%',
                margin: '0 auto',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        },
        headingStrong: {
            fontWeight: 'normal',
            fontFamily: 'MaisonNeue-Bold',
            fontSize: '18px',
            lineHeight: '28px'
        },
        body1: {
            fontWeight: 'normal',
            fontFamily: 'MaisonNeue-Book',
            fontSize: '18px',
            lineHeight: '24px',
            '&.ellipsis': {
                display: '-webkit-box',
                maxWidth: '100%',
                margin: '0 auto',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        },
        body2: {
            fontWeight: 'normal',
            fontFamily: 'MaisonNeue-Book',
            fontSize: '16px',
            lineHeight: '24px',
            '&.ellipsis': {
                display: '-webkit-box',
                maxWidth: '100%',
                margin: '0 auto',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        },
        bodyStrong: {
            fontWeight: 'normal',
            fontFamily: 'MaisonNeue-Bold',
            fontSize: '16px',
            lineHeight: '24px'
        },
        caption: {
            fontWeight: 'normal',
            fontFamily: 'MaisonNeue-Book',
            fontSize: '12px',
            lineHeight: '20px',
            '&.ellipsis': {
                display: '-webkit-box',
                maxWidth: '100%',
                margin: '0 auto',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        }
    },
    zIndex: {
        tooltip: zIndexes.TOOLTIP,
        appBar: zIndexes.APP_BAR,
        modal: zIndexes.MODAL
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        },
        MuiButtonGroup: {
            defaultProps: {
                disableRipple: true
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none'
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.06), 0px 0px 12px 0px rgba(130, 130, 130, 0.08)',
                    border: '1px solid #E0E0E0',
                    borderRadius: 8
                }
            }
        },

        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    boxShadow: 'none',
                    backgroundColor: white,
                    color: black,
                    padding: 16,
                    border: `1px solid ${grey5}`,
                    borderRadius: 16
                },
                arrow: {
                    color: grey5
                }
            }
        }
    }
});

export const flashHeight = 52;
export const copyFontFamily = 'MaisonNeue-Book';
export const boldFontFamily = 'MaisonNeue-Bold';
export const editorialFontFamily = 'GT-Alpina-Condensed-Bold';

const BaseLayout = ({ children }) => {
    return (
        <>
            <StyledEngineProvider injectFirst={true}>
                <ThemeProvider theme={sweepTheme}>
                    <StylesProvider injectFirst={true}>{children}</StylesProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </>
    );
};

export default BaseLayout;
