import React from 'react';
import { ApplicationProvider } from './src/app/common/components/ApplicationProvider';
import { SWEEP_TREES } from './src/components/planting';
import { wrapI18nPageElement } from './src/lib/i18n';
import './src/styles/emoji.css';
import './src/styles/global.css';

export const wrapPageElement = wrapI18nPageElement;

export const wrapRootElement = ({ element }) => {
    return <ApplicationProvider>{element}</ApplicationProvider>;
};

export const onInitialClientRender = () => {
    if (process.env.NODE_ENV === 'production') {
        console.log(SWEEP_TREES);
    }
};

export const shouldUpdateScroll = ({ routerProps }) => {
    const state = routerProps.location.state;
    return !state ? true : !state.disableScrollUpdate;
};
