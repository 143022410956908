export enum GradientTypes {
    RED_BLUE_WHITE = 'redBlueWhite',
    BLUE_WHITE_WHITE = 'blueWhiteWhite',
    BLUE_BLUE_WHITE = 'blueBlueWhite',
    RANDOM = 'random',
    FROM_STRING = 'fromString',
    BLUE_GLACIER_WHITE_WHITE = 'blueGlacierWhiteWhite',
    BLUE_GLACIER_BLUE_GLACIER_WHITE = 'blueGlacierBlueGlacierWhite',
    BLUE_WHITE_BLUE_GLACIER = 'blueWhiteBlueGlacier',
    WHITE_BROWN_FIRE_RED = 'whiteBrownFireRed'
}
