import React from 'react';
import { Box, Typography, TypographyVariant as Variant, styled } from '@mui/material';
import SwEmoji from '../SwEmoji';

const StyledTypo = styled(Typography)<{ variant: Variant; position?: string }>`
    padding-left: ${(props) => (props.position === 'left' ? 0 : '8px')};
    padding-right: ${(props) => (props.position === 'left' ? '8px' : 0)};
`;

const SwTextWithEmoj = (props: SwTextWithEmojProps) => {
    return (
        <Box alignItems={'center'} display={'flex'}>
            {props.position === 'left' && (
                <StyledTypo align={props.position} variant={props.variant}>
                    <SwEmoji emoji={props.emoj} />
                </StyledTypo>
            )}
            <Typography variant={props.variant}>{props.children}</Typography>
            {props.position !== 'left' && (
                <StyledTypo variant={props.variant}>
                    <SwEmoji emoji={props.emoj} />
                </StyledTypo>
            )}
        </Box>
    );
};

interface SwTextWithEmojProps {
    variant: Variant;
    position?: string;
    children: string;
    emoj: string;
}

export default SwTextWithEmoj;
