import React from 'react';
import { Box, Grid, styled } from '@mui/material';
import SwCardBlock from '../../../components/v1/SwCardBlock';
import SwContainer from '../../../components/v1/SwContainer';
import SwTextWithEmoj from '../../../components/v1/common/SwTextWithEmoj';
import SwSpacer from '../../../lib/components/bedrock/layout/SwSpacer';
import SwTypography from '../../../lib/components/bedrock/typography/SwTypography';
import { GradientTypes } from '../../../lib/enums/GradientTypes';
import { getGradientCss } from '../../../lib/helpers/color';

const GradientBackgroundBox = styled(Box)`
    background: ${() => getGradientCss(GradientTypes.WHITE_BROWN_FIRE_RED, true)};
    min-height: 100vh;
`;

const StyledA = styled('a')`
    color: black;
    &:hover {
        cursor: pointer;
    }
`;

export const ClientErrorPage: React.FunctionComponent = () => {
    return (
        <GradientBackgroundBox>
            <SwSpacer direction={'vertical'} spacing={4} />
            <SwContainer>
                <Grid container={true}>
                    <Grid item={true} xs={12}>
                        <Box>
                            <SwCardBlock
                                body={
                                    <>
                                        <SwSpacer direction={'vertical'} spacing={2} />
                                        <SwTypography variant={'body1'}>
                                            Looks like something went wrong! Try to refresh the page. If the problem
                                            persists{' '}
                                            <StyledA href={'mailto:support@sweep.net'}>
                                                <u>drop us a note</u>
                                            </StyledA>
                                            .
                                        </SwTypography>
                                    </>
                                }
                                container={true}
                                header={
                                    <SwTextWithEmoj emoj={':boom:'} variant={'h3'}>
                                        OOPS!
                                    </SwTextWithEmoj>
                                }
                                padding={32}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </SwContainer>
        </GradientBackgroundBox>
    );
};
