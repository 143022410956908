import React from 'react';
import { CircularProgress, CircularProgressProps } from '@mui/material';

export type SwSpinnerProps = CircularProgressProps;

const SwSpinner: React.FunctionComponent<SwSpinnerProps> = (props) => {
    const { color } = props;

    return <CircularProgress {...props} color={color} />;
};

export default SwSpinner;
