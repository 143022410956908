/* New color v2 mapping to use everywhere */

// Core
export const skyBlueStrong = '#283FFF';
export const skyBlueDark = '#7E8CFF';
export const skyBlueMedium = '#B4BDEA';
export const skyBlueLight = '#E3E7FB';

export const glacierBlueStrong = '#48CCFF';
export const glacierBlueDark = '#91DBF8';
export const glacierBlueMedium = '#BBE5F5';
export const glacierBlueLight = '#E6F0F4';

export const fireRedStrong = '#FF4D2A';
export const fireRedDark = '#FF927F';
export const fireRedMedium = '#FFC7BC';
export const fireRedLight = '#F4DAD5';

// Greys
export const black = '#000000';
export const grey1 = '#4D4D4D';
export const grey2 = '#6E6E6E';
export const grey3 = '#8A8A8A';
export const grey4 = '#BDBDBD';
export const grey5 = '#E0E0E0';
export const grey5RGB = [224, 224, 224];
export const grey6 = '#F2F2F2';
export const grey6RGB = [242, 242, 242];
export const grey7 = '#F9F9F9';
export const white = '#FFFFFF';

// Extended
export const clayRedStrong = '#BE3C2C';
export const clayRedDark = '#CD7B70';
export const clayRedMedium = '#DCA9A2';
export const clayRedLight = '#F5D6D2';

export const seaBlueStrong = '#063286';
export const seaBlueDark = '#6D88BA';
export const seaBlueMedium = '#ADBCD7';
export const seaBlueLight = '#DFE5F1';

export const coralPinkStrong = '#B133BB';
export const coralPinkDark = '#D682DC';
export const coralPinkMedium = '#EBC2E2';
export const coralPinkLight = '#F4E2F0';

export const flowerPinkStrong = '#671494';
export const flowerPinkDark = '#A977DD';
export const flowerPinkMedium = '#D3B0E6';
export const flowerPinkLight = '#E7D6F1';

export const algaeGreenStrong = '#0B896B';
export const algaeGreenDark = '#6FB0A0';
export const algaeGreenMedium = '#A4C7BF';
export const algaeGreenLight = '#DDEDE1';

export const grassGreenStrong = '#1AC734';
export const grassGreenDark = '#95DB83';
export const grassGreenMedium = '#B7E6AB';
export const grassGreenLight = '#DDF3D8';

export const cropBrownStrong = '#EECA6F';
export const cropBrownDark = '#DFC275';
export const cropBrownMedium = '#F2D999';
export const cropBrownLight = '#F7E9C5';

export const soilBrownStrong = '#B98231';
export const soilBrownDark = '#DAB378';
export const soilBrownMedium = '#E3CDAC';
export const soilBrownLight = '#F1E7D8';

// Special
export const gradientRed = '#FF0407';
