export enum zIndexes {
    // Common zIndexes, try to use it at much at possible
    NEGATIVE = -1,
    TOOLTIP = 1501,
    DROPDOWN = 1200,
    TOOLTIP_BELOW_APP_BAR = 1390,
    NAV_BAR_BELOW_APP_BAR = 1395,
    APP_BAR = 1400,
    TOOLTIP_ABOVE_APP_BAR = 1410,
    DRAWER = 1450,
    FLASH = 1500,
    MODAL = 1600,
    TOOLTIP_ABOVE_ALL = 1999,
    CONFETTI = 2000,

    // Custom zIndexes, related to specific features
    VERTICAL_STEPPER_DOT = 1,
    EDIT_OVERLAY = 10,
    EDIT_OVERLAY2 = 11,
    TOOLTIP_IN_MODAL = 1700
}
