import * as d3 from 'd3-color';
import { random, shuffle } from 'lodash';
import ColorImage from '../../assets/images/color.png';
import {
    algaeGreenDark,
    algaeGreenLight,
    algaeGreenStrong,
    clayRedDark,
    clayRedLight,
    clayRedStrong,
    coralPinkDark,
    coralPinkLight,
    coralPinkStrong,
    cropBrownDark,
    cropBrownLight,
    cropBrownStrong,
    fireRedDark,
    fireRedLight,
    fireRedStrong,
    flowerPinkDark,
    flowerPinkLight,
    flowerPinkStrong,
    glacierBlueDark,
    glacierBlueLight,
    glacierBlueStrong,
    gradientRed,
    grassGreenDark,
    grassGreenLight,
    grassGreenStrong,
    seaBlueDark,
    seaBlueLight,
    seaBlueStrong,
    skyBlueDark,
    skyBlueLight,
    skyBlueStrong,
    soilBrownDark,
    soilBrownLight,
    soilBrownStrong,
    white
} from '../components/bedrock/SwColors';
import { GradientTypes } from '../enums/GradientTypes';
import { getANumberBetween0and9FromAString } from './string';

const orderedLightColor = [
    algaeGreenLight,
    clayRedLight,
    coralPinkLight,
    cropBrownLight,
    fireRedLight,
    flowerPinkLight,
    glacierBlueLight,
    grassGreenLight,
    seaBlueLight,
    skyBlueLight,
    soilBrownLight
];

export const getOrderedLightColor = (index: number) => {
    return orderedLightColor[index % orderedLightColor.length];
};

const orderedStrongColor = [
    algaeGreenStrong,
    clayRedStrong,
    coralPinkStrong,
    cropBrownStrong,
    fireRedStrong,
    flowerPinkStrong,
    glacierBlueStrong,
    grassGreenStrong,
    seaBlueStrong,
    skyBlueStrong,
    soilBrownStrong
];

export const getOrderedStrongColor = (index: number) => {
    return orderedStrongColor[index % orderedStrongColor.length];
};

const orderedDarkColor = [
    algaeGreenDark,
    clayRedDark,
    coralPinkDark,
    cropBrownDark,
    fireRedDark,
    flowerPinkDark,
    glacierBlueDark,
    grassGreenDark,
    seaBlueDark,
    skyBlueDark,
    soilBrownDark
];

export const getOrderedDarkColor = (index: number) => {
    return orderedDarkColor[index % orderedDarkColor.length];
};

export function getRGBA(hex, opacity): string {
    const color = d3.color(hex);
    color.opacity = opacity;

    return color.rgb().toString();
}

export function getGradientCss(
    gradientType: GradientTypes,
    isSureToLookGood = true,
    generateFromThisString: string = null
) {
    // isSureToLookGood = no randomise of colors order and angles

    let colors = [];

    // RED_BLUE_WHITE is over-represented to get more chance to be picked up.
    const gradientsArray = [
        GradientTypes.BLUE_GLACIER_WHITE_WHITE,
        GradientTypes.BLUE_WHITE_WHITE,
        GradientTypes.BLUE_BLUE_WHITE,
        GradientTypes.BLUE_GLACIER_BLUE_GLACIER_WHITE,
        GradientTypes.BLUE_WHITE_BLUE_GLACIER,
        GradientTypes.RED_BLUE_WHITE,
        GradientTypes.RED_BLUE_WHITE,
        GradientTypes.RED_BLUE_WHITE,
        GradientTypes.RED_BLUE_WHITE,
        GradientTypes.WHITE_BROWN_FIRE_RED
    ];

    // if random pick up a random element in the gradient option array
    gradientType =
        gradientType === GradientTypes.RANDOM ? gradientsArray[random(0, gradientsArray.length - 1)] : gradientType;

    gradientType =
        gradientType === GradientTypes.FROM_STRING
            ? gradientsArray[getANumberBetween0and9FromAString(generateFromThisString)]
            : gradientType;

    switch (gradientType) {
        case GradientTypes.BLUE_GLACIER_WHITE_WHITE:
            colors = [glacierBlueStrong, white, white];
            break;
        case GradientTypes.BLUE_BLUE_WHITE:
            colors = [skyBlueStrong, white, skyBlueStrong];
            break;
        case GradientTypes.BLUE_GLACIER_BLUE_GLACIER_WHITE:
            colors = [glacierBlueStrong, glacierBlueStrong, white];
            break;
        case GradientTypes.BLUE_WHITE_BLUE_GLACIER:
            colors = [glacierBlueStrong, skyBlueStrong, white];
            break;
        case GradientTypes.RED_BLUE_WHITE:
            colors = [gradientRed, white, skyBlueStrong];
            break;
        case GradientTypes.BLUE_WHITE_WHITE:
            colors = [skyBlueStrong, white, white];
            break;
        case GradientTypes.WHITE_BROWN_FIRE_RED:
            colors = [white, cropBrownStrong, fireRedStrong];
            break;
    }
    colors = isSureToLookGood ? colors : shuffle(colors);

    return `url('${ColorImage}'),
    linear-gradient(180deg, ${getRGBA(colors[0], 0.9)}, ${getRGBA(colors[0], 0)} 40%),
    radial-gradient(
        ellipse at top left,
        ${getRGBA(colors[0], 0.99)},
        ${getRGBA(colors[0], 0)} 20%
    ),
    linear-gradient(0deg, ${getRGBA(colors[1], 0.8)}, ${getRGBA(colors[1], 0)} 20%),
    linear-gradient(150deg, ${getRGBA(colors[0], 0.9)}, ${getRGBA(colors[0], 0)} 70%),
    radial-gradient(ellipse at top left, ${getRGBA(colors[1], 0.99)}, ${getRGBA(colors[1], 0)} 40%),
    linear-gradient(220deg, ${getRGBA(colors[2], 0.9)}, ${getRGBA(colors[2], 0)} 70%),
    radial-gradient(ellipse at bottom, ${getRGBA(colors[1], 0.99)}, ${getRGBA(colors[1], 0)} 50%),
    linear-gradient(90deg, ${colors[0]}, ${colors[2]})`;
}
