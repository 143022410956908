import React from 'react';
import { useTheme, styled } from '@mui/material';

const StyledDiv = styled('div')<SwVerticalSpacerProps>`
    height: ${(props) => useTheme().spacing(props.spacing)};
    @media (max-width: 960px) {
        height: ${(props) =>
            props.spacingXs ? useTheme().spacing(props.spacingXs) : useTheme().spacing(props.spacing)};
    }
`;

const SwVerticalSpacer = (props: SwVerticalSpacerProps) => {
    return <StyledDiv {...props} />;
};

interface SwVerticalSpacerProps {
    spacing: number;
    spacingXs?: number;
}

export default SwVerticalSpacer;
