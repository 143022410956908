export const SWEEP_TREES = `                                               |
                                              -x-
                                               |
              v .   ._, |_  .,
           \`-._\/  .  \ /    |/_
               \\  _\, y | \//
         _\_.___\\, \\/ -.\||
           \`7-,--.\`._||  / / ,
           /'     \`-. \`./ / |/_.'
                     |    |//
                     |_    /
                     |-   |
                     |   =|
                     |    |
--------------------/ ,  . \--------._

So much more than planting!
https://jobs.sweep.net
`;
