import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TypographyVariant as Variant, styled } from '@mui/material';
import { Link } from 'gatsby';
import SwTypography from '@lib/components/bedrock/typography/SwTypography';
import { black } from '../../lib/components/bedrock/SwColors';

interface StyledLinkProps {
    underlined?: string;
    nohoverunderlined?: string;
    hovercolor?: string;
    color?: string;
    noGatsbyLink?: boolean;
    children?: React.ReactNode;
}
const StyledGatsbyLink = styled(Link)<StyledLinkProps>`
    cursor: pointer;
    text-decoration: ${(props) => (props.underlined ? 'underline' : 'none')};
    color: ${(props) => (props.color ? props.color : black)};
    &:hover {
        text-decoration: ${(props) => (props.nohoverunderlined ? 'none' : 'underline')};
        color: ${(props) => (props.hovercolor ? props.hovercolor : props.color ? props.color : black)};
    }
    &:visited {
        color: ${(props) => (props.color ? props.color : black)};
        &:hover {
            text-decoration: ${(props) => (props.nohoverunderlined ? 'none' : 'underline')};
            color: ${(props) => (props.hovercolor ? props.hovercolor : props.color ? props.color : black)};
        }
    }
`;

const StyledA = styled('a')<StyledLinkProps>`
    cursor: pointer;
    text-decoration: ${(props) => (props.underlined ? 'underline' : 'none')};
    color: ${(props) => (props.color ? props.color : black)};
    &:hover {
        text-decoration: ${(props) => (props.nohoverunderlined ? 'none' : 'underline')};
        color: ${(props) => (props.hovercolor ? props.hovercolor : props.color ? props.color : black)};
    }
    &:visited {
        color: ${(props) => (props.color ? props.color : black)};
        &:hover {
            text-decoration: ${(props) => (props.nohoverunderlined ? 'none' : 'underline')};
            color: ${(props) => (props.hovercolor ? props.hovercolor : props.color ? props.color : black)};
        }
    }
`;

const SwLink = (props: SwLinkProps) => {
    const { withI18n = true, noGatsbyLink, href } = props;
    const { i18n } = useTranslation();

    const children = (
        <SwTypography
            bold={props.bold}
            component={props.component ?? 'span'}
            noWrap={props.noWrap}
            uppercase={props.uppercase}
            variant={props.variant}
        >
            {props.children}
        </SwTypography>
    );

    const i18nTo = useMemo(
        () =>
            `${!noGatsbyLink && withI18n && i18n.language && i18n.language !== 'en' ? `/${i18n.language}` : ''}${href}`,
        [withI18n, i18n, href, noGatsbyLink]
    );

    if (noGatsbyLink) {
        return (
            <StyledA
                color={props.color}
                data-test-id={props.dataTestId}
                hovercolor={props.hovercolor}
                href={href}
                nohoverunderlined={props.noHoverUnderlined ? 'true' : undefined}
                target={!props.noNewTab ? '_blank' : ''}
                underlined={props.underlined ? 'true' : undefined}
                onClick={props.onClick}
            >
                {children}
            </StyledA>
        );
    } else {
        return (
            <StyledGatsbyLink
                color={props.color}
                data-test-id={props.dataTestId}
                hovercolor={props.hovercolor}
                nohoverunderlined={props.noHoverUnderlined ? 'true' : undefined}
                target={!props.noNewTab ? '_blank' : ''}
                to={i18nTo}
                underlined={props.underlined ? 'true' : undefined}
            >
                {children}
            </StyledGatsbyLink>
        );
    }
};

export interface SwLinkProps {
    color?: string;
    href: string;
    variant: Variant;
    component?: React.ElementType;
    underlined?: boolean;
    noHoverUnderlined?: boolean;
    noNewTab?: boolean;
    hovercolor?: string;
    noGatsbyLink?: boolean;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    dataTestId?: string;
    noWrap?: boolean;
    children?: React.ReactNode;
    withI18n?: boolean;
    uppercase?: boolean;
    bold?: boolean;
}
export default SwLink;
