import React from 'react';
import { Box, Button, ButtonProps, css, styled } from '@mui/material';
import clsx from 'clsx';
import { isDefined } from '../../../helpers/object';
import {
    algaeGreenDark,
    algaeGreenStrong,
    black,
    fireRedDark,
    fireRedStrong,
    grey2,
    grey3,
    grey4,
    grey5,
    grey6,
    skyBlueDark,
    skyBlueMedium,
    skyBlueStrong,
    white
} from '../SwColors';
import { colors } from '../fundations';
import SwSpinner from '../loader/SwSpinner';

export enum SwButtonKind {
    Default = 'default',
    Primary = 'primary',
    Secondary = 'secondary',
    Danger = 'danger',
    SecondaryDanger = 'secondaryDanger',
    Success = 'success',
    Link = 'link',
    Tertiary = 'tertiary'
}

export const buttonColorScheme = {
    [SwButtonKind.Default]: {
        default: {
            backgroundColor: black,
            color: white,
            borderColor: black
        },
        focus: {
            backgroundColor: black,
            color: white,
            boxShadowColor: skyBlueMedium,
            borderColor: black
        },
        hover: {
            backgroundColor: grey2,
            color: white,
            borderColor: grey2
        },
        active: {
            backgroundColor: grey2,
            color: black,
            borderColor: grey2
        },
        disabled: {
            backgroundColor: grey5,
            color: grey4,
            borderColor: grey5
        }
    },
    [SwButtonKind.Primary]: {
        default: {
            backgroundColor: skyBlueStrong,
            color: white,
            borderColor: skyBlueStrong
        },
        focus: {
            backgroundColor: skyBlueStrong,
            color: white,
            boxShadowColor: skyBlueMedium,
            borderColor: skyBlueStrong
        },
        hover: {
            backgroundColor: skyBlueDark,
            color: white,
            borderColor: skyBlueDark
        },
        active: {
            backgroundColor: skyBlueStrong,
            color: white,
            borderColor: skyBlueStrong
        },
        disabled: {
            backgroundColor: grey5,
            color: grey4,
            borderColor: grey5
        }
    },
    [SwButtonKind.Secondary]: {
        default: {
            backgroundColor: white,
            color: black,
            borderColor: grey5
        },
        focus: {
            backgroundColor: white,
            color: black,
            boxShadowColor: skyBlueMedium,
            borderColor: grey5
        },
        hover: {
            backgroundColor: grey6,
            color: black,
            borderColor: grey5
        },
        active: {
            backgroundColor: grey5,
            color: black,
            borderColor: grey5
        },
        disabled: {
            backgroundColor: white,
            color: grey4,
            borderColor: grey5
        }
    },
    [SwButtonKind.Danger]: {
        default: {
            backgroundColor: fireRedStrong,
            color: white,
            borderColor: fireRedStrong
        },
        focus: {
            backgroundColor: fireRedStrong,
            color: white,
            boxShadowColor: fireRedStrong,
            borderColor: fireRedStrong
        },
        hover: {
            backgroundColor: fireRedDark,
            color: white,
            borderColor: fireRedDark
        },
        active: {
            backgroundColor: fireRedStrong,
            color: white,
            borderColor: fireRedStrong
        },
        disabled: {
            backgroundColor: grey5,
            color: grey4,
            borderColor: grey5
        }
    },
    [SwButtonKind.SecondaryDanger]: {
        default: {
            backgroundColor: white,
            color: fireRedStrong,
            borderColor: grey5
        },
        focus: {
            backgroundColor: white,
            color: fireRedStrong,
            boxShadowColor: fireRedStrong,
            borderColor: grey5
        },
        hover: {
            backgroundColor: grey6,
            color: fireRedStrong,
            borderColor: grey5
        },
        active: {
            backgroundColor: grey5,
            color: fireRedStrong,
            borderColor: grey5
        },
        disabled: {
            backgroundColor: white,
            color: grey4,
            borderColor: grey5
        }
    },
    [SwButtonKind.Success]: {
        default: {
            backgroundColor: algaeGreenStrong,
            color: white,
            borderColor: algaeGreenStrong
        },
        focus: {
            backgroundColor: algaeGreenStrong,
            color: white,
            boxShadowColor: algaeGreenStrong,
            borderColor: algaeGreenStrong
        },
        hover: {
            backgroundColor: algaeGreenDark,
            color: white,
            borderColor: algaeGreenDark
        },
        active: {
            backgroundColor: algaeGreenStrong,
            color: white,
            borderColor: algaeGreenStrong
        },
        disabled: {
            backgroundColor: grey5,
            color: grey4,
            borderColor: grey5
        }
    },
    [SwButtonKind.Tertiary]: {
        default: {
            backgroundColor: 'transparent',
            color: black,
            borderColor: 'transparent'
        },
        focus: {
            backgroundColor: colors.surface.subdued,
            color: black,
            boxShadowColor: skyBlueMedium,
            borderColor: 'transparent'
        },
        hover: {
            backgroundColor: colors.surface.subdued,
            color: black,
            borderColor: 'transparent'
        },
        active: {
            backgroundColor: 'transparent',
            color: black,
            borderColor: 'transparent'
        },
        disabled: {
            backgroundColor: 'transparent',
            color: grey4,
            borderColor: 'transparent'
        }
    },
    /**  @deprecated use tertiary instead */
    [SwButtonKind.Link]: {
        default: {
            backgroundColor: 'transparent',
            color: black,
            borderColor: 'transparent'
        },
        focus: {
            backgroundColor: grey6,
            color: black,
            boxShadowColor: skyBlueMedium,
            borderColor: 'transparent'
        },
        hover: {
            backgroundColor: grey6,
            color: grey3,
            borderColor: 'transparent'
        },
        active: {
            backgroundColor: grey5,
            color: black,
            borderColor: 'transparent'
        },
        disabled: {
            backgroundColor: 'transparent',
            color: grey4,
            borderColor: 'transparent'
        }
    }
};

type StyledButtonProps = {
    $kind: SwButtonKind;
    $hasChildren: boolean;
};

const StyledButton = styled(Button)<StyledButtonProps>`
    & {
        box-sizing: border-box;
        border-radius: ${({ theme }) => theme.spacing(1)};
        box-shadow: none;
        font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
        font-family: ${({ theme }) => theme.typography.body2.fontFamily};
        font-size: ${({ theme }) => theme.typography.body2.fontSize};
        line-height: ${({ theme }) => theme.typography.body2.lineHeight};
        text-transform: none;
        white-space: nowrap;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: ${({ $kind }) => buttonColorScheme[$kind].default.backgroundColor};
        color: ${({ $kind }) => buttonColorScheme[$kind].default.color};
        border: solid 1px ${({ $kind }) => buttonColorScheme[$kind].default.borderColor};

        padding: ${({ theme }) => theme.spacing(0.75)} ${({ theme }) => theme.spacing(2)};
        height: 32px;
        min-width: 32px;

        :hover {
            background-color: ${({ $kind }) => buttonColorScheme[$kind].hover.backgroundColor};
            color: ${({ $kind }) => buttonColorScheme[$kind].hover.color};
            border-color: ${({ $kind }) => buttonColorScheme[$kind].hover.borderColor};
        }

        :focus-visible {
            background-color: ${({ $kind }) => buttonColorScheme[$kind].focus.backgroundColor};
            color: ${({ $kind }) => buttonColorScheme[$kind].focus.color};
            box-shadow: 0px 0px 0px 3px ${({ $kind }) => buttonColorScheme[$kind].focus.boxShadowColor};
            border-color: ${({ $kind }) => buttonColorScheme[$kind].focus.borderColor};
            z-index: 1;
        }

        &.active,
        :active {
            background-color: ${({ $kind }) => buttonColorScheme[$kind].active.backgroundColor};
            color: ${({ $kind }) => buttonColorScheme[$kind].active.color};
            border-color: ${({ $kind }) => buttonColorScheme[$kind].active.borderColor};
        }

        &.Mui-disabled {
            background-color: ${({ $kind }) => buttonColorScheme[$kind].disabled.backgroundColor};
            color: ${({ $kind }) => buttonColorScheme[$kind].disabled.color};
            border-color: ${({ $kind }) => buttonColorScheme[$kind].disabled.borderColor};
            cursor: disabled;
        }

        &.MuiButton-sizeSmall {
            padding: ${({ theme }) => theme.spacing(0.75)};
        }

        &.MuiButton-sizeLarge {
            padding: ${({ theme }) => theme.spacing(1)} ${({ theme }) => theme.spacing(2)};
            height: 40px;
            min-width: 40px;
        }

        .MuiButton-startIcon,
        .MuiButton-endIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 24px;
        }

        .MuiButton-startIcon {
            margin-right: ${({ theme }) => theme.spacing(1)};
        }

        .MuiButton-endIcon {
            margin-left: ${({ theme }) => theme.spacing(1)};
        }

        ${({ $hasChildren }) =>
            $hasChildren === false
                ? css`
                      .MuiButton-startIcon,
                      .MuiButton-endIcon {
                          margin: 0;
                      }
                  `
                : ''}

        &.loading {
            .MuiButton-startIcon,
            .MuiButton-endIcon,
            .content {
                visibility: hidden;
            }

            .loader {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }
    }
`;

export type SwButtonProps = Omit<ButtonProps, 'color' | 'ariaLabel'> & {
    kind: SwButtonKind;
    ariaLabel?: string;
    dataTestId?: string;
    active?: boolean;
    loading?: boolean;
};

export const SwButton: React.FunctionComponent<SwButtonProps> = React.forwardRef((props, ref) => {
    const { kind, ariaLabel, dataTestId, active, loading, children, ...other } = props;

    const loadingProps = loading
        ? {
              disabled: true
          }
        : {};

    return (
        <StyledButton
            {...other}
            {...loadingProps}
            $hasChildren={isDefined(children)}
            $kind={kind}
            aria-label={ariaLabel}
            className={clsx(
                {
                    active: active,
                    loading: loading
                },
                kind
            )}
            data-test-id={dataTestId}
            ref={ref}
        >
            {loading === true ? (
                <Box alignItems={'center'} className={'loader'} display={'flex'} justifyContent={'center'}>
                    <SwSpinner color={'inherit'} size={16} />
                </Box>
            ) : null}

            <Box alignItems={'inherit'} className={'content'} display={'inherit'} justifyContent={'inherit'}>
                {children}
            </Box>
        </StyledButton>
    );
});

export default SwButton;
