export const colors = {
    common: {
        black: '#000',
        white: '#fff'
    },
    text: {
        default: '#000',
        secondary: '#6E6E6E',
        onAction: '#fff',
        disabled: '#BDBDBD',
        destructive: '#B81F00',
        selected: '#283FFF'
    },
    action: {
        primary: '#283FFF',
        destructive: '#FF4D2A'
    },
    border: '#E0E0E0',
    surface: {
        default: '#fff',
        subdued: '#F9F9F9',
        background: '#f2f2f2'
    },
    grey: {
        100: '#F9F9F9',
        200: '#F2F2F2',
        300: '#E0E0E0',
        400: '#BDBDBD',
        500: '#8A8A8A',
        600: '#6E6E6E',
        700: '#4D4D4D'
    },
    sky: {
        100: '#E3E7FB',
        200: '#B4BDEA',
        300: '#7E8CFF',
        400: '#283FFF',
        500: '#0010A3'
    },
    fire: {
        100: '#FCE6E2',
        200: '#FFC7BC',
        300: '#FF927F',
        400: '#FF4D2A',
        500: '#B81F00'
    },
    crop: {
        100: '#FFEEC2',
        200: '#FFE794',
        300: '#FFD439',
        400: '#EBB900',
        500: '#B89000'
    },
    grass: {
        100: '#DDF3D8',
        200: '#B7E6AB',
        300: '#95DB83',
        400: '#1AC734',
        500: '#008113'
    },
    algea: {
        100: '#D9F2E5',
        200: '#A4DFC2',
        300: '#68CA99',
        400: '#0B895F',
        500: '#22543B'
    },
    glacier: {
        100: '#E6F0F4',
        200: '#BBE5F5',
        300: '#91DBF8',
        400: '#1FC0FF',
        500: '#0192CB'
    },
    sea: {
        100: '#DFE5F1',
        200: '#ADBCD7',
        300: '#6D88BA',
        400: '#073792',
        500: '#052561'
    },
    flower: {
        100: '#E7D6F1',
        200: '#D3B0E6',
        300: '#A977DD',
        400: '#7419A4',
        500: '#4B0F6C'
    },
    coral: {
        100: '#F4E2F0',
        200: '#EBC2E2',
        300: '#D682DC',
        400: '#AF32B8',
        500: '#732178'
    }
} as const;
