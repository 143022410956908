import React from 'react';
import { useTheme, styled } from '@mui/material';

export interface SwSpacerProps {
    direction: 'vertical' | 'horizontal';
    spacing: number;
    spacingXs?: number;
}

export interface StyledSpacerProps {
    $spacing: number;
    $spacingXs?: number;
}

const StyledVerticalSpacer = styled('div')<StyledSpacerProps>`
    height: ${({ $spacing }) => useTheme().spacing($spacing)};

    @media (max-width: 960px) {
        height: ${({ $spacing, $spacingXs }) =>
            $spacingXs ? useTheme().spacing($spacingXs) : useTheme().spacing($spacing)};
    }
`;

const StyledHorizontalSpacer = styled('div')<StyledSpacerProps>`
    width: ${({ $spacing }) => useTheme().spacing($spacing)};
`;

const SwSpacer: React.FunctionComponent<SwSpacerProps> = (props) => {
    const { direction, spacing, spacingXs } = props;

    switch (direction) {
        case 'vertical':
            return <StyledVerticalSpacer $spacing={spacing} $spacingXs={spacingXs} />;
        case 'horizontal':
            return <StyledHorizontalSpacer $spacing={spacing} />;
    }
};

export default SwSpacer;
