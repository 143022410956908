import React from 'react';
import { BoxProps, styled } from '@mui/material';

const StyledDivContainer = styled('div')<Omit<BoxProps, 'maxHeight'> & { background: string; maxHeight?: boolean }>`
    background: ${(props) => (props.background ? props.background : 'none')};
    display: flex;
    width: 100%;
    justify-content: center;
    height: ${(props) => (props.maxHeight ? '100%' : '')};
`;

const StyledDiv = styled('div')<{ withMaxWidth?: boolean }>`
    display: flex;
    max-width: ${(props) => (props.withMaxWidth ? '1250px' : 'none')};
    width: 100%;
`;

const Gutter = styled('div')<{ disableGutterInXs: boolean; hasOverflowingList?: boolean }>`
    width: ${({ theme }) => theme.spacing(8)};
    flex: 0 0 ${({ theme }) => theme.spacing(8)};

    ${(props) => {
        if (props.hasOverflowingList) {
            return `
                @media (max-width: 600px) {
                    /* until sm */
                    width: 20px;
                    flex: 0 0 20px;
                }
            
                @media (min-width: 600px) and (max-width: 960px) {
                    /* until md */
                    width: 20px;
                    flex: 0 0 20px;
                }
            `;
        }
        if (props.disableGutterInXs) {
            return `
                @media (max-width: 600px) {
                    /* until sm */
                    width: 0px;
                    flex: 0 0 0;
                }
            
                @media (min-width: 600px) and (max-width: 960px) {
                    /* until md */
                    width: 20px;
                    flex: 0 0 20px;
                }
            `;
        } else {
            return `
                @media (max-width: 960px) {
                    /* until md */
                    width: 20px;
                    flex: 0 0 20px;
                }
            `;
        }
    }}
`;

interface SwContainerProps extends Omit<BoxProps, 'maxHeight' | 'ref' | 'color'> {
    background?: string;
    disableGutterInXs?: boolean;
    noGutter?: boolean;
    withMaxWidth?: boolean;
    maxHeight?: boolean;
    hasOverflowingList?: boolean;
    children: any;
}

const SwContainer: React.FunctionComponent<SwContainerProps> = (props) => {
    const { noGutter, children, maxHeight, disableGutterInXs, hasOverflowingList, background } = props;

    return (
        <StyledDivContainer background={background} maxHeight={maxHeight}>
            <StyledDiv {...props}>
                {!noGutter && <Gutter disableGutterInXs={disableGutterInXs} hasOverflowingList={hasOverflowingList} />}
                {children}
                {!noGutter && <Gutter disableGutterInXs={disableGutterInXs} />}
            </StyledDiv>
        </StyledDivContainer>
    );
};

export default SwContainer;
