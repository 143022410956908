import React from 'react';
import { ClientErrorPage } from './ClientErrorPage';

export interface ErrorBoundaryState {
    hasError: boolean;
}

// This has to be a class component since componentDidCatch and getDerivedStateFromError
// does not exist in functional ones
// https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
class ErrorBoundary extends React.Component<{ children }, ErrorBoundaryState> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <ClientErrorPage />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
